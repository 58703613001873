.project-grid {
    background-color: black;
    padding-top: 10vh;
    padding-bottom: 5vh;
    padding-left: 10vh;
    padding-right: 10vh;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Change the number '3' to control the column count */
    align-items: center;
    justify-content: center;
    gap: 2vh; /* Adjust the gap between grid items */
  }

  .project-grid-container {
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-left: 10vw;
    padding-right: 10vw;
    background-color: black;
  }

  .project-grid-description {
    background: rgb(0,215,255);
    background: linear-gradient(90deg, rgba(40,40,40,1) 0%,  rgba(80,80,80,1) 100%);
    border-radius: 3vh;;
    color: white;
    align-items: flex-start;
    text-align: left;
    display: flex;
    flex-flow: column;
    padding-top: 3%;
    padding-bottom: 3vh;
    padding-left: 3vh;
    padding-right: 3vh;
  }

  .project-description-text {
    
  }

  .project-description-header {
    display: flex;
    flex-flow: row;
    align-items: baseline;
    justify-content: baseline;
    gap: 4vw;
  }
