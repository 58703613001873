
.bio-container {
    background: rgb(0,215,255);
    background: linear-gradient(90deg, rgba(0,215,255,1) 0%, rgba(59,75,175,1) 50%, rgba(0,0,0,1) 100%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row wrap;
    gap: 10vw;
}

.bio-images {
    padding-left: 5vw;
    padding-top: 3vh;
    padding-bottom: 3vh;
    width: 30vw;
    height: 30vw;
    min-width: 200px;
    min-height: 200px;
}

.bio-image {
    position: relative;
    width: 100%;
    height: 100%;
    
    border: solid black 5px;
}

.bio-text {
    color: white;
    max-width: 40vw;
    text-align: left;
}