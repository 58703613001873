.work-section-split {
    margin-top: 5vh;
    margin-bottom: 5vh;
    border-color: rgba(255, 255, 255, 0.3);
}

.work-history-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vh;
}