.education-container {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 100%;
}

.education-school-header {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: flex-start;
    gap: 5vw;
}

.education-school {

}

.education {
    display: flex;
    flex: 1 2 auto;
}

.education-seal {
    position: relative;
    padding: 20px;
    max-width: 20%;
    width: min-content;
    opacity: 1;
}

.education-degree-container {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: flex-start;
}

.education-degree {

}

.education-description {
    display: flex;
    align-items: center;
    justify-content: center;
}

.education-description-text {
    align-items: center;
    justify-content: start;
}