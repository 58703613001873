.job-container {
    display: flex;
    flex-direction: column;
    color: white;
    background: rgb(0,215,255);
    background: linear-gradient(90deg, rgba(40,40,40,1) 0%,  rgba(80,80,80,1) 100%);
    border-radius: 3vh;
    color: white;
    padding-top: 3%;
    padding-bottom: 3vh;
    padding-left: 3vh;
    padding-right: 3vh;
}

.job-header {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: flex-start;
    gap: 5vw;
}

.job-company {

}

.job-title {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: flex-start;
}

.job-description {

}

.job-description-text {
    text-align: start;
    line-height: 25px;
    white-space: pre-line;
}