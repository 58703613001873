.project-pane a {
    text-decoration: none;
}

.project-container {
    display: flex;
    align-items: center; 
    justify-content: center;
    width: 20vw;
    height: 20vw; 
}

.project-pane {
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-pane__image {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0.5;
}

.project-pane__title {
    font-size: calc(10px + 1vw);
    font-weight: bold;
    color: white;
    position: absolute;
}