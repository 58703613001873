.App {
  text-align: center;
  min-width: 300px;
}

.App a {
  text-decoration: none;
  color: #61dafb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: fixed;
  z-index: 100;
  min-width: 100%;
  background: rgb(0,215,255);
  background: linear-gradient(90deg, rgba(0,215,255,1) 0%, rgba(59,75,175,1) 50%, rgba(0,0,0,1) 100%);
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: black;
  padding-left: 2vw;
  padding-right: 10vw;
}

.App-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2vw;
  font-size: calc(5px + 2vmin);
  padding-right: 5vw;
}

.App-gradient-background {
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 10vw;
  padding-right: 10vw;
  background: rgb(0,215,255);
  background: linear-gradient(90deg, rgba(0,215,255,1) 0%, rgba(59,75,175,1) 50%, rgba(0,0,0,1) 100%);
  color: white;
}

.App-black-background {
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 10vw;
  padding-right: 10vw;
  background-color: black;
  color: white;
}